import React from 'react';
import logo from './logo.svg';
import './App.css';
import { AiFillTwitterCircle, AiFillGithub } from 'react-icons/ai'
import { GiMagicGate } from 'react-icons/gi'

function App() {
  return (
    <main className="max-w-5xl mx-auto">

      <section id="header" className="text-center flex justify-between shadow-sm py-4 rounded-b px-8">
        <a href="https://hoshinolabs.com" className="flex items-center">
          <img src={logo} className="h-6 mr-3 sm:h-9" alt="" />
          <span className="self-center text-xl font-semibold whitespace-nowrap">HoshinoLabs.COM</span>
        </a>
        {/*
        <div className="flex items-center">
          <a href="#" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-md text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700">Login</a>
        </div>
        */}
        <ul className="flex items-center">
          <li>
            <a href="https://hoshinolabs.dev"><GiMagicGate /></a>
          </li>
        </ul>
      </section>

      <section id="profile" className="text-center mt-4 flex flex-col justify-center px-8">
        <div className="mx-auto w-56 h-56 relative overflow-hidden md:h-96 md:w-96 mb-2">
          <img src="https://avatars.githubusercontent.com/u/8447771" className="rounded-full " alt="" />
        </div>
        <h1 className="text-3xl md:text-5xl font-medium text-indigo-300">HOSHINO IKUKO</h1>
        <h3 className="text-xl md:text-2xl">Technical Engineer</h3>
        <p className="text-md py-1 text-gray-800 md:text-xl">イワシは激怒した。必ず、かのバグだらけのVRチャットSDKを除かなければならぬと決意した。イワシにはユニティーがわからぬ。ブレンダーもわからぬ。何もわからぬ。とてもつらい。</p>
        <div className="text-5xl py-4 flex justify-center gap-16">
          <a href="https://twitter.com/magi_ikuko"rel="noopener noreferrer" target="_blank"><AiFillTwitterCircle /></a>
          <a href="https://github.com/ikuko"rel="noopener noreferrer" target="_blank"><AiFillGithub /></a>
          <a href="https://hoshinolabs.booth.pm/"rel="noopener noreferrer" target="_blank">
            <img src="https://booth.pm/static-images/pwa/icon_size_144.png" className="w-12 h-12 rounded-full" alt="" />
          </a>
        </div>
      </section>

      <section id="footer" className="text-center mt-4 justify-center shadow-sm py-8 rounded px-8 bg-black">
        <div className="text-white md:text-center">
          <p className="text-center text-sm text-gray-300 sm:text-center">
            © 2022 HoshinoLabs. All Rights Reserved.
          </p>
        </div>
      </section>

    </main>
  );
}

export default App;
